<template>
    <LayoutDefault>
        <Loading :active="loading" />
        
        <div class="content-wrapper">
            <router-link :to="{ name: 'Visits' }" class="custom-back m-0 ttU">
                <i class="fas fa-arrow-circle-left"></i>
                <span>Atrás</span>
            </router-link>

            <Toast
                :message="toastMessage"
                v-model:active="toastActive"
                :type="toastType"
            />

            <section class="content mt-3">
                <div class="container-fluid">
                    <form action="" class="form" @submit="saveData" ref="form">
                        <div class="inputs">
                            <div style="margin-top: 30px; margin-bottom: 30px" v-if="formData.comments">
                              <h3>Comentarios</h3>
                              <p>Comentarios del coach al finalizar la visita:</p>
                              <i style="text-style: italic">{{formData.comments}}</i>
                              <hr />
                            </div>

                            <div class="form-group">
                                <label for="exampleInputPassword1">Fecha</label>
                                <input
                                    type="date"
                                    class="form-control"
                                    placeholder="Fecha"
                                    name="date"
                                    v-model="formData['date_visit']"
                                    :min="minDate"
                                    required
                                >
                            </div>

                            <div class="form-group">
                                <label for="exampleFormControlSelect1">Agregar EDS</label>
                                <select
                                    class="eds form-control"
                                    id="exampleFormControlSelect1"
                                    name="station"
                                    v-model="formData['station_id']"
                                    required
                                >
                                    <option
                                        v-for="(station, index) in stations"
                                        :value="station.id"
                                        :key="index"
                                    >
                                        <span>{{ station.service_station }} (#{{ station.pbl }})</span>
                                    </option>
                                </select>
                            </div>

                            <div class="form-group">
                                <label for="exampleFormControlSelect2">Encuesta</label>
                                <select
                                    class="surveys form-control"
                                    multiple="multiple"
                                    name="surveys"
                                    v-model="formData['surveys']"
                                    id="exampleFormControlSelect2"
                                    required
                                >
                                    <option
                                        v-for="(survey, index) in surveys"
                                        :value="survey.id"
                                        :key="index"
                                    >
                                        {{survey.title}}
                                    </option>
                                </select>
                            </div>

                            <div class="form-group">
                                <label for="exampleFormControlSelect3">Seleccionar Coach</label>
                                <select
                                    class="coaches form-control"
                                    id="exampleFormControlSelect3"
                                    name="station"
                                    v-model="formData['coach_id']"
                                    required
                                >
                                    <option
                                        v-for="(coach, index) in coaches"
                                        :value="coach.id"
                                        :key="index"
                                    >
                                        {{ coach.name }} {{ coach.lastName }}
                                    </option>
                                </select>
                            </div>

                            <div class="form-group">
                                <label for="exampleFormControlSelect4">Tipo de visita</label>
                                <select
                                    class="form-control"
                                    id="exampleFormControlSelect4"
                                    v-model="formData['type']"
                                    required
                                >
                                    <option value="Cortesia">Cortesia</option>
                                    <option value="Visita entrenamiento">Visita entrenamiento</option>
                                    <option value="Rebranding">Rebranding</option>
                                    <option value="Apoyo evento">Apoyo evento</option>
                                    <option value="Diagnóstico">Diagnóstico</option>
                                </select>
                            </div>

                            <button type="submit" class="submit-button">
                                Guardar
                            </button>
                        </div>  
                    </form>
                </div>
            </section>
        </div>
    </LayoutDefault>
</template>

<script>
import LayoutDefault from "../layout/LayoutAdmin"
import Toast from "../components/Toast"
import Loading from "../components/Loading"

import $ from "jquery"

import stationService from "@/services/stationService"
import visitsService from "@/services/visitsService"

export default {
    name: 'CreateOrEditVisit',

    data: () => ({
        date: new Date(),
        minDate: new Date().toISOString().split("T")[0],
        loading:false,
        coaches: [],
        formData: {},
        form: {},
        toastType: "",
        toastActive: false,
        toastMessage: "",
        edit: false
    }),

    async mounted() {
        const param = new URL(location.href).searchParams.get("idVisit")

        if (param) {
            this.getEdit(param)
            this.minDate = null
        }

        // Need to wait for the selects initialization
        // to fetch the data. To fix this problem, just
        // remove the jQuery dependency...
        await this.initSelects();
        await this.getAllStations() 

        // If the variable param is empty, we are creating
        // a new visit, then we should set all the surveys
        // but the 'REF_IMAGEN_EVALUATION'. This should
        // be a param that comes from the server.
        if (param) {
          return
        }

        $('.surveys')
          .val(
            this.surveys
              .filter(s => s.ref_survey !== 'REF_IMAGEN_EVALUATION')
              .map(s => s.id),
          )
          .trigger('change');
    },

    updateData() {
        this.form = Object.assign({}, this.form, this.formData)
    },

    methods: {
        async getEdit(id) {
            const resp= await visitsService.getVisit(id)

            this.formData = resp;
            // Will set the value with the format 'YYYY-mm-dd' all the additional
            // information will be removed; such as seconds or time zone.
            this.formData.date_visit = this.formData.date_visit.substring(0, 10);
            this.formData.surveys = resp.surveys?.map(item => item.id);
            this.formData.type = resp.metadata?.find(item => item.name === 'type')?.value
            this.edit = true
        },
    
        async getAllStations() {
            this.loading = true
            
            const res = await stationService.getAllStationsWithoutUsers()
            
            if (res && res.response) {
                if (res.response?.data) {
                    this.stations = res.response.data
                    return
                }

                this.stations = res.response
            }
            
            const resp = await visitsService.getSurveys()
            const resCoaches = await visitsService.getCoaches()

            this.surveys = resp
            this.coaches = resCoaches
            this.loading = false
        },
        
        async saveData(e) {   
            e.preventDefault()

            this.loading = true;

            let res = null;
            let form = {...this.formData}
            let type = form.type
            delete form.type
            form.metadata = [
              {name: 'type', value: type},
            ]

            if (this.edit) {
                res = await visitsService.updateVisit(this.formData.id, form)
            } else {
                res = await visitsService.addVisit(form)
            }

            if (! res.error) {
                e.target.reset()

                this.formData = {}
                this.toastActive = true
                this.toastMessage = 'Exitosamente guardado'
                this.toastType = "success"

                setTimeout(() => {
                    this.$router.push("/dashboard/visits")
                }, 1000)
            }

            else {
                this.toastActive = true
                this.toastMessage = 'Se ha presentado un error, intenta nuevamente'
                this.toastType = "error"
            }

            this.loading = false
        },

        async initSelects() {
          // Sleep 500ms
          await new Promise(
            (resolve) => setTimeout(() => resolve(), 500),
          )

          $(".coaches")
            .select2()
            .trigger("change")
            .on("change", () => {
                //getting tags every change
                this.formData['coach_id'] = $(".coaches").select2("data")[0]['id'];
            });
              
          $(".eds")
            .select2()
            .trigger("change")
            .on("change", () => {
                // getting tags every change
                this.formData['station_id'] = $(".eds").select2("data")[0]['id'];
            });
          
          $(".surveys")
            .select2()
            // .val([2])
            .trigger("change")
            .on("change", () => {
                // Getting tags every change
                const surveys = $(".surveys").select2("data")
                this.formData['surveys'] = surveys.map(item => item.id)
            });
    },

    //clear hour
    formatDate(d){
        d = d.split(' ')[0];
        return d;
    },
    },
    
    components: {
        LayoutDefault,
        Loading,
        Toast
        // Datetime
    },
}
</script>

<style lang="scss" scoped>
    .content-wrapper {
        padding: 20px;
        .form {
            display: flex;
            align-items: flex-start;
            margin: 0 auto;
            flex-wrap: wrap;
            justify-content: space-around;
            .inputs {
                width: 40%;
                .button {
                margin-top: 1em;
                }
            }
            .image {
                width: 40%;
                &-container {
                display: flex;
                align-items: center;
                max-height: 360px;
                overflow: hidden;
                }
                .button {
                margin-top: 1em;
                }
                img {
                width: 630px;
                }
            }
            &-group {
                width: 100%;
                position: relative;
            }
            &-control {
                padding: 1em;
                border-radius: 8px;
                resize: none;
                height: inherit;
                &:focus {
                border-radius: 8px;
                }
            }
        }
        button {
            margin: 10px;
            border: none;
            border-radius: 50px;
            color: #fff;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            /* font-family: 'Proxima Nova'; */
            font-weight: 300;
            width: 100%;
            height: 42px;
            margin: auto;
            .iconBtn {
                width: 17px;
                // height: 17px;
                margin-right: 7px;
            }
            p {
                margin-top: 2px;
                // line-height: 16px;
                margin-bottom: 0;
            }
            &:first-child {
                background-color: #1E22AA;
            }
            &:last-child {
                background-color: #FF6900;
            }
            &:focus {
                outline-color: #fff;
            }
        }
    }
</style>

